import { Box, Button, Chip, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { lightBackground1 } from '../../mainTheme';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import LoadingSpinner from '../Shared/LoadingSpinner';
import * as Yup from 'yup';
import { Field, Form, Formik, FormikProps } from 'formik';
import MaskedInput from '../Shared/MaskedInput';
import DeleteUserModal from './components/DeleteUserModal';
import UserDeletedModal from './components/UserDeletedModal';
import {
  deleteCaregiverById,
  getCaregiverById,
  setDeleteCaregiverSuccess,
  updateCaregiverById,
} from '../../store/facility/actionCreators';
import { getFacilityRoleById } from '../../shared/utils';
import ChangeRoleModal from './components/ChangeRoleModal';

const baseStyle = {
  color: '#000',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '24px',
  letterSpacing: '0.2px',
};

const headingStyle = {
  ...baseStyle,
  fontSize: '22px',
  lineHeight: '30px',
  letterSpacing: '0.36px',
};

const headLineStyle = {
  ...baseStyle,
  fontSize: '18px',
};

const contentStyle = {
  ...baseStyle,
  color: 'var(--Font-F3---Body, #585863)',
  fontSize: '16px',
  paddingBottom: 2,
};

const validationSchema = Yup.object().shape({
  phone: Yup.string()
    .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Phone number is not valid')
    .required('Phone number is required'),
});

const CaregiverProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const { caregiver, isLoading, deleteCaregiverSuccess } = useSelector(
    (state: RootState) => state.facility
  );

  const [isEditing, setIsEditing] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isUserDeletedModalOpen, setUserDeletedModalOpen] = useState(false);
  const [isChangeRoleModalOpen, setChangeRoleModalOpen] = useState(false);

  const formikRef = useRef<FormikProps<{ phone: string }>>(null);

  const handleBackClick = () => {
    history.goBack();
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    const values = formikRef?.current?.values;
    // Await to call the user updated details when this completes before moving on
    await dispatch(
      updateCaregiverById(Number(id), {
        phone: values?.phone || '',
      })
    );
    // Dispatch getCaregiverById after updateCaregiverById completes
    await dispatch(getCaregiverById(Number(id)));
    setIsEditing(false);
  };

  const handleDeleteUser = () => {
    dispatch(deleteCaregiverById(Number(id)));
  };

  const handleCloseUserDeletedModal = () => {
    setUserDeletedModalOpen(false);
    history.push('/v2/caregivers');
  };

  useEffect(() => {
    if (deleteCaregiverSuccess) {
      setDeleteModalOpen(false);
      setUserDeletedModalOpen(true);
      dispatch(setDeleteCaregiverSuccess(false)); // Reset the success state
    }
  }, [deleteCaregiverSuccess, dispatch, history]);

  const handleChangeRoleClick = () => {
    setChangeRoleModalOpen(true);
  };

  const handleRoleChangeSubmit = async (values: { role: number }) => {
    if (caregiver && Number(values.role) !== caregiver.facility_role_id) {
      await dispatch(
        updateCaregiverById(Number(id), {
          facility_role_id: Number(values.role),
        })
      );
      await dispatch(getCaregiverById(Number(id)));
    }
    setChangeRoleModalOpen(false);
  };

  useEffect(() => {
    dispatch(getCaregiverById(Number(id)));
  }, [dispatch, id]);

  if (!caregiver || isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Box
      marginLeft={30}
      display="flex"
      flexDirection="column"
      height="100vh"
      bgcolor={lightBackground1}
      p={2}
    >
      <Box>
        <Button
          variant="text"
          color="secondary"
          onClick={handleBackClick}
          data-testid="back-button"
        >
          Back
        </Button>
      </Box>
      <Box py={2} display="flex" justifyContent="space-between">
        <Box>
          <Typography sx={headingStyle}>{caregiver?.combined_name}</Typography>
          <Chip label={getFacilityRoleById(caregiver.facility_role_id)} />
        </Box>
        <Box display="flex" gap={2}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleChangeRoleClick}
            data-testid="change-role-button"
          >
            Change role
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => setDeleteModalOpen(true)}
            data-testid="delete-user-button"
          >
            Delete user
          </Button>
        </Box>
      </Box>
      <Box>
        <Box display="flex" alignItems="baseline" pb={2}>
          <Typography sx={headLineStyle}>Contact details</Typography>
          <Button
            type="submit"
            variant="text"
            color="secondary"
            onClick={isEditing ? handleSaveClick : handleEditClick}
          >
            {isEditing ? 'Save' : 'Edit'}
          </Button>
          {isEditing && (
            <Button
              variant="text"
              color="secondary"
              onClick={() => {
                setIsEditing(false);
              }}
            >
              Cancel
            </Button>
          )}
        </Box>

        <Typography sx={headLineStyle}>Email</Typography>
        <Typography sx={contentStyle}>{caregiver?.email}</Typography>

        <Typography sx={headLineStyle}>Phone</Typography>
        {isEditing ? (
          <Formik
            innerRef={formikRef}
            initialValues={{
              phone: caregiver.phone || '',
            }}
            validationSchema={validationSchema}
            onSubmit={() => {}}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Box pb={1} sx={{ width: '50%' }}>
                  <Field
                    name="phone"
                    format="(###) ###-####"
                    placeholder="(123)-456-7890"
                    component={MaskedInput}
                    variant="standard"
                    size="small"
                    sx={{
                      fontSize: 18,
                      fontWeight: 'bold',
                      mb: 1,
                      color: 'red',
                    }}
                  />
                </Box>
              </Form>
            )}
          </Formik>
        ) : (
          <>
            <Typography sx={contentStyle}>
              {caregiver?.phone || 'No data'}
            </Typography>
          </>
        )}
      </Box>
      {isDeleteModalOpen && (
        <DeleteUserModal
          onClose={() => setDeleteModalOpen(false)}
          onDelete={handleDeleteUser}
        />
      )}

      {isUserDeletedModalOpen && (
        <UserDeletedModal onClose={handleCloseUserDeletedModal} />
      )}
      {isChangeRoleModalOpen && (
        <ChangeRoleModal
          onClose={() => setChangeRoleModalOpen(false)}
          onSubmit={handleRoleChangeSubmit}
          initialValues={{ role: caregiver.facility_role_id }}
        />
      )}
    </Box>
  );
};

export default CaregiverProfile;

import moment from 'moment';
import { Reducer } from 'redux';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../../shared/constants';
import {
  FacilityInfo,
  FacilityStore,
  FacilityStoreAction,
  FacilityStoreActionTypes,
  FacilityAdminInvite,
  FacilityDevice,
} from './facilityTypes';
import { formatDate } from '../../shared/utils';

const initialState: FacilityStore = {
  facilities: null,
  facilityInfo: null,
  goToFacilityPage: false,
  error: null,
  isLoading: false,
  showSuccessAlert: false,
  showErrorAlert: false,
  showDuplicateAlert: false,
  showDuplicateAlertFacility: false,
  showDuplicateAlertCareTeam: false,
  caregivers: [],
  caregiver: null,
  addCaregiverSuccess: false,
  deleteCaregiverSuccess: false,
};

const facilityReducer: Reducer<FacilityStore, FacilityStoreAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FacilityStoreActionTypes.POPULATE_FACILITIES: {
      const { facilities } = action.payload;
      facilities.forEach((facility: FacilityInfo) => {
        let createdAt = moment(facility.created_at);
        facility.created_at = createdAt.isValid()
          ? createdAt.format(DATE_FORMAT)
          : '-';
      });
      let newState = {
        ...state,
        facilities: facilities,
        goToFacilityPage: false,
      };
      return newState;
    }
    case FacilityStoreActionTypes.POPULATE_FACILITY_DEVICES: {
      const { facilityDevices } = action.payload;
      facilityDevices.devices.forEach((device: FacilityDevice) => {
        const owner = device.owner;
        const synced_by = device.synced_by;
        device.owner_name = owner
          ? `${owner.first_name} ${owner.last_name}`
          : '-';
        device.synced_by_name = synced_by
          ? `${synced_by.first_name} ${synced_by.last_name}`
          : '-';
      });
      let newState = {
        ...state,
        facilityDevices: facilityDevices,
      };
      return newState;
    }
    case FacilityStoreActionTypes.CLEAR_FACILITY_DEVICES: {
      let newState = {
        ...state,
      };
      delete newState.facilityDevices;
      return newState;
    }
    case FacilityStoreActionTypes.POPULATE_FACILITY_INFO: {
      const { facilityInfo } = action.payload;
      let createdAt = moment(facilityInfo.created_at);
      facilityInfo.created_at = createdAt.isValid()
        ? createdAt.format(DATE_FORMAT)
        : '-';
      let newState = {
        ...state,
        facilityInfo: facilityInfo,
      };
      return newState;
    }
    case FacilityStoreActionTypes.FACILITY_ADDED: {
      let newState = {
        ...state,
        goToFacilityPage: true,
      };
      return newState;
    }
    case FacilityStoreActionTypes.FACILITY_API_ERR: {
      const { error } = action.payload;
      console.log('error: ', error);
      let newState = {
        ...state,
        error: error,
      };
      return newState;
    }
    case FacilityStoreActionTypes.SET_FACILITY_ADMINS: {
      const { facilityId, admins } = action.payload;
      if (state.facilities !== null) {
        let selectedFacility = state.facilities.find(
          (facility) => facility.id === Number(facilityId)
        );
        if (selectedFacility) {
          selectedFacility.admins = admins;
        }
        let newState = {
          ...state,
          facilities: [...state.facilities],
        };
        return newState;
      }
      return state;
    }
    case FacilityStoreActionTypes.SET_FACILITY_INVITES: {
      const { facilityId, invites } = action.payload;
      if (state.facilities !== null) {
        let selectedFacility = state.facilities.find(
          (facility) => facility.id === Number(facilityId)
        );
        invites.forEach((invite: FacilityAdminInvite) => {
          let expiresAt = moment(invite.expires_at);
          invite.expires_at = expiresAt.isValid()
            ? expiresAt.format(DATE_TIME_FORMAT)
            : '-';
        });
        if (selectedFacility) {
          selectedFacility.invites = invites;
        }
        let newState = {
          ...state,
          facilities: [...state.facilities],
        };
        return newState;
      }
      return state;
    }
    case FacilityStoreActionTypes.FACILITY_SET_LOAD_STATE: {
      const { isLoading } = action.payload;
      let newState = {
        ...state,
        isLoading: isLoading,
      };
      return newState;
    }
    case FacilityStoreActionTypes.SET_SUCCESS_ALERT: {
      const { showSuccessAlert } = action.payload;
      let newState = {
        ...state,
        showSuccessAlert: showSuccessAlert,
      };
      return newState;
    }
    case FacilityStoreActionTypes.SET_ERROR_ALERT: {
      const { showErrorAlert } = action.payload;
      let newState = {
        ...state,
        showErrorAlert: showErrorAlert,
      };
      return newState;
    }
    case FacilityStoreActionTypes.SET_DUPLICATE_ALERT: {
      const { showDuplicateAlert } = action.payload;
      let newState = {
        ...state,
        showDuplicateAlert: showDuplicateAlert,
      };
      return newState;
    }
    case FacilityStoreActionTypes.SET_DUPLICATE_ALERT_FACILITY: {
      const { showDuplicateAlertFacility } = action.payload;
      let newState = {
        ...state,
        showDuplicateAlertFacility: showDuplicateAlertFacility,
      };
      return newState;
    }
    case FacilityStoreActionTypes.SET_DUPLICATE_ALERT_CARETEAM: {
      const { showDuplicateAlertCareTeam } = action.payload;
      let newState = {
        ...state,
        showDuplicateAlertCareTeam: showDuplicateAlertCareTeam,
      };
      return newState;
    }
    case FacilityStoreActionTypes.POPULATE_CAREGIVERS: {
      const { caregivers } = action.payload;
      let newState = {
        ...state,
        caregivers: caregivers.map((caregiver) => ({
          ...caregiver,
          last_login_at: moment(caregiver.last_login_at).isValid()
            ? formatDate(caregiver.last_login_at)
            : '-',
        })),
      };
      return newState;
    }
    case FacilityStoreActionTypes.POPULATE_CAREGIVER_BY_ID: {
      const { caregiver } = action.payload;
      let newState = {
        ...state,
        caregiver: caregiver,
      };
      return newState;
    }
    case FacilityStoreActionTypes.UPDATE_CAREGIVER_BY_ID: {
      let newState = {
        ...state,
      };
      return newState;
    }
    case FacilityStoreActionTypes.DELETE_CAREGIVER_BY_ID: {
      const { customerId } = action.payload;
      const newCaregivers = state.caregivers.filter(
        (caregiver) => caregiver.customer_id !== customerId
      );
      let newState = {
        ...state,
        caregivers: newCaregivers,
        deleteCaregiverSuccess: true,
      };
      return newState;
    }
    case FacilityStoreActionTypes.SET_DELETE_CAREGIVER_SUCCESS: {
      const { success } = action.payload;
      return {
        ...state,
        deleteCaregiverSuccess: success,
      };
    }
    case FacilityStoreActionTypes.ADD_CAREGIVER: {
      return {
        ...state,
        addCaregiverSuccess: true,
      };
    }
    case FacilityStoreActionTypes.SET_ADD_CAREGIVER_SUCCESS: {
      const { success } = action.payload;
      return {
        ...state,
        addCaregiverSuccess: success,
      };
    }
    case FacilityStoreActionTypes.RESET_ADD_CAREGIVER_SUCCESS: {
      return {
        ...state,
        addCaregiverSuccess: false,
      };
    }

    default:
      return state;
  }
};

export default facilityReducer;

import { Box } from '@mui/material';
import React from 'react';
import { faGear, faCircleUser } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightBlack, topBarBg } from '../../../mainTheme';

const Topbar = () => {
  return (
    <Box
      bgcolor={topBarBg}
      height="72px"
      display="flex"
      justifyContent="end"
      alignItems="center"
    >
      <Box p={2} display="flex" gap={2}>
        <FontAwesomeIcon icon={faGear} size="lg" color={lightBlack} />
        <FontAwesomeIcon icon={faCircleUser} size="lg" color={lightBlack} />
      </Box>
    </Box>
  );
};

export default Topbar;

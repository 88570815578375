import isNull from 'lodash/isNull';
import { DateTime, Duration } from 'luxon';
import moment from 'moment';
import {
  AgeRangeValue,
  BasicSelectedValue,
  MedAdminTimeValue,
  RequiredFilterValues,
} from '../store/reporting/reportingTypes';
import { SoftwareVersion } from '../store/software/softwareTypes';
import { ONE_YEAR_IN_MSECONDS } from './constants';

// URL/API Functions

export const formatUrl = (host: string, path: string = '') => {
  return 'https://' + host + '/' + path;
};

export const jsonToFormUrlEncoded = (json: { [key: string]: any }) => {
  let params = new URLSearchParams(json);
  return params.toString();
};

// Host Functions

interface Config {
  rest_host: string;
  cookie_domain: string;
}

let config: Config = {
  rest_host: '',
  cookie_domain: '',
};

export const setRestHost = (rest_host: string) => {
  config.rest_host = rest_host;
};
export const getRestHost = () => {
  return config.rest_host;
};
export const setCookieDomain = (cookie_domain: string) => {
  config.cookie_domain = cookie_domain;
};
export const getCookieDomain = () => {
  return config.cookie_domain;
};

// Cookie Functions

export const setCookie = (
  cname: string,
  cvalue: string,
  expires_in?: number
) => {
  var d = new Date();
  if (expires_in) {
    d.setTime(d.getTime() + expires_in * 1000);
  } else {
    d.setTime(d.getTime() + ONE_YEAR_IN_MSECONDS);
  }
  let domain = getCookieDomain();
  var expires = ' expires=' + d.toUTCString();
  document.cookie =
    cname + '=' + cvalue + ';' + expires + ';path=/;domain=' + domain;
};

export const getCookie = (cookie_name: string) => {
  var cookies = document.cookie.split(/[\s,;]+/);
  for (var i = 0; i < cookies.length; i++) {
    var c = cookies[i].split('=');
    if (c[0] === cookie_name) {
      return c[1];
    }
  }
};

export const deleteCookie = (name: string) => {
  let epoch = new Date(0);
  let epoch_string = epoch.toUTCString();
  let domain = getCookieDomain();
  document.cookie = `${name}=; domain=${domain}; path=/; expires=${epoch_string}`;
};

export const deleteToken = () => {
  deleteCookie('access_token');
  deleteCookie('refresh_token');
  deleteCookie('token_type');
  redirectToLogin();
};

export const redirectToLogin = () => {
  window.location.href = '/auth/login';
};

// Misc Functions

export const getPrivilegeById = (privilege_id: number) => {
  switch (privilege_id) {
    case 1:
      return 'standard';
    case 2:
      return 'customer_care';
    case 3:
      return 'engineering';
    case 4:
      return 'pharamacy_tech';
    default:
      return 'none';
  }
};

export const doesVersionExist = (
  software_versions: SoftwareVersion[] | null,
  major: number | null | undefined,
  minor: number | null | undefined,
  prerelease: number | null | undefined
) => {
  if (!isNull(software_versions)) {
    return (
      software_versions.filter(
        (version: SoftwareVersion) =>
          version.version_major === major &&
          version.version_minor === minor &&
          version.version_prerelease === prerelease
      ).length !== 0
    );
  }
};

export const getCareTeamRoleById = (careTeam_role_id: number) => {
  switch (careTeam_role_id) {
    case 1:
      return 'Professional caregiver';
    case 2:
      return 'Family administrator';
    case 3:
      return 'Caregiver';
    case 4:
      return 'Friends & family';
    case 5:
      return 'Facility scheduler';
    default:
      return 'unknown';
  }
};

export const getCareTeamRoleColorById = (careTeam_role_id: number) => {
  switch (careTeam_role_id) {
    case 1: // Professional caregiver
      return {
        backgroundColor: 'var(--Background-B2---Container, #F6F6F9)',
        borderColor: '#9999A4',
      };
    case 2: // Family administrator
      return {
        backgroundColor: 'var(--Semantics-S8---Alert-Bg, #FFF4EB)',
        borderColor: '#D18E6C',
      };
    case 3: // Caregiver
      return {
        backgroundColor: 'var(--Outline-02---P---Field-Selected, #D5C7DC)',
        borderColor: '#865E9C',
      };
    case 4: // Friends & family
      return {
        backgroundColor: 'var(--Friends-Family---Bg, #E6F7FF)',
        borderColor: '#B2E0F4',
      };
    case 5: // Facility scheduler
      return {
        backgroundColor: 'var(--Scheduler---Bg, #F0E6FF)',
        borderColor: '#865E9C',
      };
    default: // Default
      return {
        backgroundColor: 'var(--Default---Bg, #F3F3F3)',
        borderColor: '#D9D9D9',
      };
  }
};

export const getFacilityRoleById = (faclity_role_id: number) => {
  switch (faclity_role_id) {
    case 1:
      return 'Facility admin';
    case 2:
      return 'Scheduler';
    case 3:
      return 'Caregiver';
    case 4:
      return 'Owner';
    case 5:
      return 'Nurse';
    default:
      return 'unknown';
  }
};

export const getSoftwareChannelById = (channel_id: number) => {
  switch (channel_id) {
    case 1:
      return 'stable';
    case 2:
      return 'beta';
    default:
      return 'unknown';
  }
};

export const formatPhoneNumber = (phoneNumber: string) => {
  let cleaned = ('' + phoneNumber).replace(/\D/g, '');
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
};

// Filter Utils

export const hasWhiteSpace = (s: string) => {
  return /\s/g.test(s);
};

export const dropDownListFilter = (
  List: BasicSelectedValue[] | string[],
  listSelection: string | MedAdminTimeValue | undefined,
  selectedFilter: BasicSelectedValue,
  buildFilter: (
    selectedFilter: BasicSelectedValue,
    selectedFilterValue: BasicSelectedValue | BasicSelectedValue[]
  ) => void
) => {
  if (selectedFilter.value === 'state' && listSelection) {
    buildFilter(selectedFilter, {
      display: listSelection as string,
      value: listSelection,
    });
  } else {
    let selectedFilterValue = (List as BasicSelectedValue[]).filter(
      (item) => item.value === listSelection
    )[0];
    buildFilter(selectedFilter, selectedFilterValue);
  }
};

export const generateBirthDateFromAgeRange = (ageRange: string) => {
  const [toAge, fromAge] = ageRange.split('-');
  const fromBirthDate = moment().subtract(parseInt(fromAge), 'years');
  const toBirthDate = moment().subtract(parseInt(toAge), 'years');
  return {
    fromAge: fromBirthDate.format('MM/DD/yyyy'),
    toAge: toBirthDate.format('MM/DD/yyyy'),
  };
};

export const ageRangeFilter = (
  List: BasicSelectedValue[],
  listSelection: string | AgeRangeValue | undefined,
  ageRange: number[] | AgeRangeValue,
  selectedFilter: BasicSelectedValue,
  buildFilter: (
    selectedFilter: BasicSelectedValue,
    selectedFilterValue: BasicSelectedValue | BasicSelectedValue[]
  ) => void
) => {
  if (listSelection === 'custom' && (ageRange as number[]).length) {
    const [fromAge, toAge] = ageRange as number[];
    buildFilter(selectedFilter, {
      display: `${fromAge}-${toAge}`,
      value: generateBirthDateFromAgeRange(`${fromAge}-${toAge}`),
    });
  } else {
    let selectedFilterValue = List.filter(
      (item) => item.value === listSelection
    )[0];
    buildFilter(selectedFilter, selectedFilterValue);
  }
};

export const dateRangeFilter = (
  requiredFilterValues: RequiredFilterValues,
  selectedFilter: BasicSelectedValue,
  buildFilter: (
    selectedFilter: BasicSelectedValue,
    selectedFilterValue: BasicSelectedValue | BasicSelectedValue[]
  ) => void
) => {
  var selectedFilterValue: BasicSelectedValue | null = null;

  if (
    requiredFilterValues.fromDate === '' ||
    requiredFilterValues.toDate === ''
  ) {
    return;
  }

  if (requiredFilterValues.dateRange === 'datePicker') {
    selectedFilterValue = {
      display: `${requiredFilterValues.fromDate.format(
        'MM/DD/YYYY'
      )} - ${requiredFilterValues.toDate.format('MM/DD/YYYY')}`,
      value: {
        fromDate: requiredFilterValues.fromDate,
        toDate: requiredFilterValues.toDate,
      },
    };
  }

  if (requiredFilterValues.dateRange === 'yesterday') {
    selectedFilterValue = {
      display: requiredFilterValues.fromDate.format('MM/DD/YYYY'),
      value: {
        fromDate: requiredFilterValues.fromDate,
        toDate: requiredFilterValues.toDate,
      },
    };
  }
  if (requiredFilterValues.dateRange === 'last7Days') {
    selectedFilterValue = {
      display: `${requiredFilterValues.fromDate.format(
        'MM/DD/YYYY'
      )} - ${requiredFilterValues.toDate.format('MM/DD/YYYY')}`,
      value: {
        fromDate: requiredFilterValues.fromDate,
        toDate: requiredFilterValues.toDate,
      },
    };
  }
  if (requiredFilterValues.dateRange === 'last14Days') {
    selectedFilterValue = {
      display: `${requiredFilterValues.fromDate.format(
        'MM/DD/YYYY'
      )} - ${requiredFilterValues.toDate.format('MM/DD/YYYY')}`,
      value: {
        fromDate: requiredFilterValues.fromDate,
        toDate: requiredFilterValues.toDate,
      },
    };
  }
  if (requiredFilterValues.dateRange === 'last30Days') {
    selectedFilterValue = {
      display: `${requiredFilterValues.fromDate.format(
        'MM/DD/YYYY'
      )} - ${requiredFilterValues.toDate.format('MM/DD/YYYY')}`,
      value: {
        fromDate: requiredFilterValues.fromDate,
        toDate: requiredFilterValues.toDate,
      },
    };
  }
  if (selectedFilterValue) {
    buildFilter(selectedFilter, selectedFilterValue);
  }
};

export const validZipCode = (zipCode: string) => {
  var isValid = false;
  let regex = new RegExp('^[0-9]{5}?$');
  let regexZipcode = zipCode.match(regex);
  if (regexZipcode !== null && regexZipcode[0] === zipCode) {
    isValid = true;
  }

  return isValid;
};

export const splitZipCodes = (zipCode: string) => {
  let zipCodes = zipCode.split('\n');
  let zipCodeList: string[] = [];
  let invalidZipCodeList: string[] = [];
  if (zipCodes.length > 0) {
    zipCodes.forEach((element) => {
      if (element.trim() !== '') {
        if (element.indexOf(',') > -1) {
          element.split(',').forEach((item) => {
            if (item.trim() !== '') {
              if (validZipCode(item.trim())) {
                if (zipCodeList.indexOf(item.trim()) === -1) {
                  zipCodeList.push(item.trim());
                }
              } else {
                invalidZipCodeList.push(item.trim());
              }
            }
          });
        } else if (hasWhiteSpace(element)) {
          element.split(' ').forEach((item) => {
            if (item.trim() !== '') {
              if (validZipCode(item.trim())) {
                if (zipCodeList.indexOf(item.trim()) === -1) {
                  zipCodeList.push(item.trim());
                }
              } else {
                invalidZipCodeList.push(item.trim());
              }
            }
          });
        } else {
          if (validZipCode(element.trim())) {
            if (zipCodeList.indexOf(element.trim()) === -1) {
              zipCodeList.push(element.trim());
            }
          } else {
            invalidZipCodeList.push(element.trim());
          }
        }
      }
    });
  }
  return { zipCodeList: zipCodeList, invalidZipCodeList: invalidZipCodeList };
};
export const formatCityStateZip = (city: string, ...args: string[]) => {
  const _secondPart = args.filter((item) => item).join(' ');
  return city
    ? _secondPart.length
      ? city + ', ' + _secondPart
      : city
    : _secondPart;
};

export const convertMinutesToSeconds = (minutes: number) => {
  return minutes * 60;
};

export const convertSecondsToMinutes = (seconds: number) => {
  return seconds / 60;
};

// Function to convert minutes into a human-readable format using Luxon
export const formatDurationFromMinutes = (minutes: number) => {
  const duration = Duration.fromObject({ minutes });

  // Check if the duration is less than an hour
  if (minutes < 60) {
    return duration.toFormat("m 'minute(s)'");
  }
  // For durations of one hour or more, format as "h 'hour(s)' m 'minute(s)'"
  return duration
    .toFormat("h 'hour(s)' m 'minute(s)'")
    .replace(' 0 minute(s)', '');
};

// User roles for new UI implementation
export const USER_ROLES_FOR_NEW_UI = ['Facility admin'];

// Function to check if a role is eligible for the new UI
export const isRoleEligibleForNewUI = (role: string) => {
  return USER_ROLES_FOR_NEW_UI.includes(role);
};

// Function to convert Date into a human-readable format using Luxon
export const formatDate = (dateString: string) => {
  const date = DateTime.fromISO(dateString);
  return date.isValid
    ? date.toFormat('EEE MMM dd yyyy, h:mm a')
    : 'Invalid date';
};

import React, { useEffect, useState, useCallback } from 'react';
import { Drawer, List, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useHistory, useLocation } from 'react-router-dom';
import SidebarHeader from './components/SidebarHeader';
import SidebarMenuItem from './components/SidebarMenuItem';
import UIToggleSwitch from '../UIToggleSwitch/UIToggleSwitch';
import {
  // faCalendar,
  faUser,
  // faBadgeCheck,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HomeIcon from '../../images/icons/HomeIcon';
import CareGiverIcon from '../../images/icons/CareGiverIcon';
import HubIcon from '../../images/icons/HubIcon';

const drawerWidth = 240;
const collapsedWidth = 64;

const menuItems = [
  { icon: <HomeIcon />, text: 'Home', to: '/' },
  { icon: <HubIcon />, text: 'Hubs', to: '/v2/hubs' },
  // Commenting menu item which is out of scope for now
  // {
  //   icon: <FontAwesomeIcon icon={faCalendar} size="lg" />,
  //   text: 'Schedule',
  //   to: '/schedule',
  // },
  {
    icon: <FontAwesomeIcon icon={faUser} size="lg" />,
    text: 'Hub Owners',
    to: '/v2/owners',
  },
  {
    icon: <CareGiverIcon />,
    text: 'Caregivers',
    to: '/v2/caregivers',
  },
  // Commenting menu item which is out of scope for now

  // {
  //   icon: <FontAwesomeIcon icon={faBadgeCheck} size="lg" />,
  //   text: 'Team',
  //   to: '/v2/team',
  // },
];

const Sidebar: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();

  const [open, setOpen] = useState(true);
  const [selectedMenu, setSelectedMenu] = useState<string>(
    history.location.pathname
  );
  const handleDrawerToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  useEffect(() => {
    const unlisten = history.listen((location) => {
      setSelectedMenu(location.pathname);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        width: open ? drawerWidth : collapsedWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: open ? drawerWidth : collapsedWidth,
          boxSizing: 'border-box',
          transition: theme.transitions.create(['width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
      }}
    >
      <div>
        <SidebarHeader open={open} handleDrawerToggle={handleDrawerToggle} />
        <List>
          {menuItems.slice(0, 2).map((item) => {
            return (
              <Box px="16px" key={item.text}>
                <SidebarMenuItem
                  icon={item.icon}
                  text={item.text}
                  to={item.to}
                  open={open}
                  selected={selectedMenu === item.to}
                />
              </Box>
            );
          })}

          {open && (
            <Typography variant="subtitle1" sx={{ margin: theme.spacing(2) }}>
              People
            </Typography>
          )}
          <List component="div" disablePadding>
            {menuItems.slice(2).map((item) => (
              <Box px="16px" key={item.text}>
                <SidebarMenuItem
                  icon={item.icon}
                  text={item.text}
                  to={item.to}
                  open={open}
                  selected={location.pathname.startsWith(item.to)}
                />
              </Box>
            ))}
          </List>
        </List>
      </div>
      <Box p={2}>
        <UIToggleSwitch />
      </Box>
    </Drawer>
  );
};

export default React.memo(Sidebar);
